import React from 'react';
import {CategoriaPregunta} from 'src/templates';
import {Box, Container, Grid, Typography} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import {useTheme} from '@mui/styles';
import { Link } from "gatsby"
import iconoAnticipos from "src/assets/svg/ayuda/icono-anticipos.svg"
import iconoSobreCumplo from "src/assets/svg/ayuda/icono-sobre-cumplo.svg"
import iconoCavaliFactrack from "src/assets/svg/ayuda/icono-cavali-factrack.svg"
import iconoGestionPagos from "src/assets/svg/ayuda/icono-gestion-pago.svg"
import iconoEscribenos from "src/assets/svg/ayuda/icono-escribenos.svg"
import iconoDenuncia from "src/assets/svg/ayuda/icono-denuncia.svg"
import iconoPreguntasInversionistas from "src/assets/svg/ayuda/icono-preguntas-inversionistas.svg"
import iconoSaldoCumplo from "src/assets/svg/ayuda/icono-saldo-cumplo.svg"
import  HeaderHeight from "src/components/common/HeaderHeight"


export default function PreguntasFrecuentes() {
 const theme = useTheme();
 const sm = useMediaQuery(theme.breakpoints.down('md'));

 return (
  <Box sx={{ background: `${theme.palette.primary.main}20` }}>
   {process.env.GATSBY_REACT_APP_REGION === "es-PE" &&
     <HeaderHeight />
   }
   <Container>
    <Box sx={{ maxWidth: "1100px", margin: "0 auto"}}>
     <Box sx={{ padding: "72px 0"}}>
      <Box sx={{ marginBottom: "32px"}}>
       <Typography variant="h3" color="primary.main">Ayuda</Typography>
      </Box>
      <Box>
       <Typography variant="subtitle1" color="text.primary" fontWeight={400}>¿En qué te podemos ayudar hoy? </Typography>
      </Box>
      <Box sx={{ margin: "64px 0px 32px"}}>
       <Typography variant="h3" color="primary.dark" fontWeight={500}>Preguntas frecuentes</Typography>
      </Box>

      {/* Perú */}

      {process.env.GATSBY_REACT_APP_REGION === 'es-PE' && (

       <Grid container gap={4}>
        <Grid item xs={12}>
         <Link to="/ayuda/anticipos" style={{ textDecoration: "none", color: theme.palette.text.primary }}>
          <CategoriaPregunta titulo="Anticipos" descripcion="Preguntas frecuentes sobre nuestra solución de financiamiento para empresas." icono={iconoAnticipos}/>
         </Link>
        </Grid>

        <Grid item xs={12}>
         <Link to="/ayuda/inversiones" style={{ textDecoration: "none", color: theme.palette.text.primary }}>
          <CategoriaPregunta titulo="Inversiones" descripcion="Preguntas frecuentes sobre nuestra solución de inversiones." icono={iconoPreguntasInversionistas}/>
         </Link>
        </Grid>

        <Grid item xs={12}>
         <Link to="/ayuda/gestion-de-pago" style={{ textDecoration: "none", color: theme.palette.text.primary }}>
          <CategoriaPregunta titulo="Gestión de pago" descripcion="Información de soluciones financieras para adquirentes." icono={iconoGestionPagos} />
         </Link>
        </Grid>

        <Grid item xs={12}>
         <Link to="/ayuda/cavali-y-factrack" style={{ textDecoration: "none", color: theme.palette.text.primary }}>
          <CategoriaPregunta titulo="Cavali y Factrack" descripcion="Preguntas sobre el Registro Centralizado de Facturas Negociables." icono={iconoCavaliFactrack} />
         </Link>
        </Grid>

        <Grid item xs={12}>
         <Link to="/ayuda/sobre-cumplo" style={{ textDecoration: "none", color: theme.palette.text.primary }}>
          <CategoriaPregunta titulo="Sobre Cumplo" descripcion="Más información sobre nuestra empresa." icono={iconoSobreCumplo} />
         </Link>
        </Grid>

       </Grid>

      )}


      {process.env.GATSBY_REACT_APP_REGION === 'es-MX' && (

       <Grid container gap={4}>
        <Grid item xs={12}>
         <Link to="/ayuda/anticipos" style={{ textDecoration: "none", color: theme.palette.text.primary }}>
          <CategoriaPregunta titulo="Anticipos" descripcion="Preguntas frecuentes sobre nuestra solución de financiamiento para empresas." icono={iconoAnticipos} />
         </Link>
        </Grid>

        <Grid item xs={12}>
         <Link to="/ayuda/sobre-cumplo" style={{ textDecoration: "none", color: theme.palette.text.primary }}>
          <CategoriaPregunta titulo="Sobre Cumplo" descripcion="Información sobre nuestra empresa." icono={iconoSobreCumplo} />
         </Link>
        </Grid>

       </Grid>

      )}

      {process.env.GATSBY_REACT_APP_REGION === 'es-CL' && (

       <Grid container gap={4}>
        
        <Grid item xs={12}>
         <Link to="/ayuda/anticipos" style={{ textDecoration: "none", color: theme.palette.text.primary }}>
          <CategoriaPregunta titulo="Anticipos" descripcion="Preguntas frecuentes sobre nuestra solución de financiamiento para empresas." icono={iconoAnticipos}/>
         </Link>
        </Grid>

        <Grid item xs={12}>
         <Link to="/ayuda/inversiones" style={{ textDecoration: "none", color: theme.palette.text.primary }}>
          <CategoriaPregunta titulo="Inversiones" descripcion="Preguntas frecuentes sobre nuestra solución de inversiones." icono={iconoPreguntasInversionistas}/>
         </Link>
        </Grid>

        <Grid item xs={12}>
         <Link to="/ayuda/saldo-cumplo" style={{ textDecoration: "none", color: theme.palette.text.primary }}>
          <CategoriaPregunta titulo="Saldo Cumplo" descripcion="Información sobre tus montos disponibles para invertir en Cumplo." icono={iconoSaldoCumplo}/>
         </Link>
        </Grid>

        <Grid item xs={12}>
         <Link to="/ayuda/sobre-cumplo" style={{ textDecoration: "none", color: theme.palette.text.primary }}>
          <CategoriaPregunta titulo="Sobre Cumplo" descripcion="Información sobre nuestra empresa." icono={iconoSobreCumplo} />
         </Link>
        </Grid>

       </Grid>

      )}

      {process.env.GATSBY_REACT_APP_REGION === 'es-MX' && (
       <>

        <Box sx={{ margin: "64px 0px 32px"}}>
         <Typography variant="h3" color="primary.dark" fontWeight={500}>Contacto</Typography>
        </Box>

        <Grid container gap={4}>
         <Grid item xs={12}>
          <Link to="mailto:contactanos@cumplo.com" style={{ textDecoration: "none", color: theme.palette.text.primary }}>
           <CategoriaPregunta titulo="Escríbenos" descripcion="¿Tienes algo que decirnos? Cuéntanos por correo electrónico escribiendo a contactanos@cumplo.com. Estaremos pendientes de lo que necesites." icono={iconoEscribenos}/>
          </Link>
         </Grid>
        </Grid>
       </>
      )}

      <Box sx={{ margin: "64px 0px 32px"}}>
       <Typography variant="h3" color="primary.dark" fontWeight={500}>Canal de denuncias</Typography>
      </Box>

      <Grid container gap={4}>
       <Grid item xs={12}>
        <Link to="https://etica.resguarda.com/cumplo/main_es.html" target={"_blank"} style={{ textDecoration: "none", color: theme.palette.text.primary }}>
         <CategoriaPregunta titulo="Haz una denuncia" descripcion="¿Necesitas denunciar una irregularidad o fraude? Hazlo a través de Resguarda, el canal confidencial y anónimo para reportar situaciones irregulares en Cumplo." icono={iconoDenuncia}/>
        </Link>
       </Grid>
      </Grid>

     </Box>
    </Box>
   </Container>
  </Box>);
};
